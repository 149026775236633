import { ReactComponent as AnalyticsIcon } from "../assets/icons/analytics_icon.svg";
import { ReactComponent as CollectionIcon } from "../assets/icons/collection_icon.svg";
import { ReactComponent as DeleteIcon } from "../assets/icons/delete_icon.svg";
import { ReactComponent as DownArrow } from "../assets/icons/down_arrow.svg";
import { ReactComponent as DownloadIcon } from "../assets/icons/download_icon.svg";
import { ReactComponent as ErrorIcon } from "../assets/icons/error_icon.svg";
import { ReactComponent as InfoIcon } from "../assets/icons/info_icon.svg";
import { ReactComponent as NotesIcon } from "../assets/icons/notes_icon.svg";
import { ReactComponent as BackIcon } from "../assets/icons/pagination_back.svg";
import { ReactComponent as FastBackIcon } from "../assets/icons/pagination_fast_back.svg";
import { ReactComponent as FastForwardIcon } from "../assets/icons/pagination_fast_forward.svg";
import { ReactComponent as ForwardIcon } from "../assets/icons/pagination_forward.svg";
import { ReactComponent as SettingsIcon } from "../assets/icons/settings_icon.svg";
import { ReactComponent as SuccessIcon } from "../assets/icons/success_icon.svg";
import { ReactComponent as UpArrow } from "../assets/icons/up_arrow.svg";
import { ReactComponent as UpdateIcon } from "../assets/icons/update_icon.svg";
import { ReactComponent as UploadIcon } from "../assets/icons/upload_icon.svg";
import { ReactComponent as WarningIcon } from "../assets/icons/warning_icon.svg";
import { ReactComponent as SuccessUpdate } from "../assets/icons/success_update.svg";
import { ReactComponent as SuccessTakedown } from "../assets/icons/success_takedown.svg";
import { ReactComponent as SuccessSolid } from "../assets/icons/success_solid.svg";
import { ReactComponent as ErrorSolid } from "../assets/icons/error_icon_solid.svg";
import { ReactComponent as SoundWave } from "../assets/icons/sound_wave_loader.svg";
import { ReactComponent as WorldIcon } from "../assets/icons/world_icon.svg";
import { ReactComponent as PlusIcon } from "../assets/icons/plus_icon.svg";
import { ReactComponent as LogoText } from "../assets/audiowell_text_logo.svg";
import { ReactComponent as LogoFull } from "../assets/audiowell_full_logo.svg";
import { ReactComponent as LogoIcon } from "../assets/audiowell_logo.svg";
import { ReactComponent as FileIcon } from "../assets/icons/file_icon.svg";
import { ReactComponent as PauseIcon } from "../assets/icons/pause_icon.svg";
import { ReactComponent as PlayIcon } from "../assets/icons/play_icon.svg";

export {
  AnalyticsIcon,
  BackIcon,
  CollectionIcon,
  DeleteIcon,
  DownArrow,
  DownloadIcon,
  ErrorIcon,
  FastBackIcon,
  FastForwardIcon,
  ForwardIcon,
  InfoIcon,
  NotesIcon,
  SettingsIcon,
  SuccessIcon,
  UpArrow,
  UpdateIcon,
  UploadIcon,
  WarningIcon,
  SuccessUpdate,
  SuccessTakedown,
  SuccessSolid,
  ErrorSolid,
  SoundWave,
  WorldIcon,
  PlusIcon,
  LogoText,
  LogoFull,
  LogoIcon,
  FileIcon,
  PauseIcon,
  PlayIcon,
};

export const ListIcon = () => (
  <i
    className="fas fa-list text-interactive text-lg"
    style={{ width: "24px" }}
  />
);

export const DeliveryIcon = () => (
  <i
    className="fas fa-paper-plane text-interactive text-lg"
    style={{ width: "24px" }}
  />
);
