import { Track } from "../../types/TrackTypes";

export const setTrack = (track?: Track) => ({
  type: "SET_TRACK",
  payload: track,
});

export const setUpc = (upc: string) => ({
  type: "SET_UPC",
  payload: upc,
});

export const setIsPlaying = (isPlaying: boolean) => ({
  type: "SET_IS_PLAYING",
  payload: isPlaying,
});

export const playTrack = (isrc: string, upc?: string) => ({
  type: "PLAY_TRACK",
  payload: { isrc, upc },
});

export const pauseTrack = () => ({
  type: "PAUSE_TRACK",
});
