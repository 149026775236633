import React from "react";

import { Role, useAuth } from "../auth/AuthProvider";
import { useConfig } from "../config/ConfigProvider";
import NavbarSubMenu from "./NavbarSubMenu";

import { NavbarListItem } from "./NavbarListItem";
import AvatarImage from "../AvatarImage";
import {
  CollectionIcon,
  DeliveryIcon,
  ListIcon,
  LogoFull,
  NotesIcon,
  SettingsIcon,
  UploadIcon,
  WorldIcon,
} from "../../utils/Icons";
import AdminVisibleWrapper from "../adminviewable/AdminVisibleWrapper";
import { IS_DEV_ENVIRONMENT } from "../../config/Constants";

const Navbar = React.memo(() => {
  const { isAuthorizedTo, isAuthenticated, signOut, currentUser } = useAuth();
  const { usingProductionData } = useConfig();

  if (!isAuthenticated) {
    return null;
  }

  return (
    <nav className="h-screen min-w-300 bg-white p-4 border overflow-y-scroll">
      <div className="flex flex-col h-full">
        <div
          className={`flex items-center p-2 mb-16 ${
            IS_DEV_ENVIRONMENT && `border-2 rounded-full`
          } ${usingProductionData ? "border-error" : "border-interactive"}`}
        >
          <LogoFull className="h-12" />
        </div>

        <ul className="flex-grow px-2">
          <NavbarListItem
            link={{
              title: "Products",
              path: "/products",
              icon: <NotesIcon style={{ width: "24px" }} />,
            }}
          />
          <NavbarListItem
            link={{
              title: "Deliveries",
              path: "/deliveries",
              icon: <DeliveryIcon />,
            }}
          />

          <NavbarListItem
            link={{
              title: "Bulk",
              path: "/bulk",
              icon: <UploadIcon style={{ width: "24px" }} />,
            }}
          />

          {/**          <NavbarListItem
            link={{
              title: "Analytics",
              path: "/analytics",
              icon: <AnalyticsIcon />,
            }}
          /> */}
          <NavbarListItem
            link={{
              title: "Collections",
              path: "/collections",
              icon: <CollectionIcon style={{ width: "24px" }} />,
            }}
          />
          <NavbarListItem
            link={{
              title: "Landing Pages",
              path: "/landingpages",
              icon: <WorldIcon />,
            }}
          />
          {isAuthorizedTo(
            Role.ADMIN,
            Role.FINANCIAL_READ,
            Role.SUPER_ADMIN
          ) && (
            <NavbarSubMenu
              menuName="financialMenu"
              empty
              mainLink={{
                title: "Financial",
                path: "/financial",
                icon: (
                  <div className="flex justify-center items-center">
                    <i
                      className="fas fa-dollar-sign text-interactive text-lg pl-1"
                      style={{ width: "24px" }}
                    />
                  </div>
                ),
              }}
              linkList={[
                { title: "Settlements", path: "/financial/settlements" },
                {
                  title: "Statements and Invoices",
                  path: "/financial/statements-and-invoices",
                },

                { title: "Accounting", path: "/financial/accounting" },
                { title: "Contracts", path: "/financial/contracts" },
              ]}
            />
          )}

          <NavbarSubMenu
            menuName="toolsMenu"
            empty={true}
            mainLink={{
              title: "Tools",
              path: "/tools",
              icon: <SettingsIcon style={{ width: "24px" }} />,
            }}
            linkList={[
              { title: "Artist Picker", path: "/tools/artistpicker" },

              { title: "Track Recycler", path: "/tools/trackrecycler" },
              { title: "BlobChecker", path: "/tools/blobchecker" },
            ]}
          />
          <NavbarSubMenu
            menuName="administrationMenu"
            empty={true}
            mainLink={{
              title: "Administration",
              path: "/administration",
              icon: <ListIcon />,
            }}
            linkList={[
              {
                title: "Categories & Genres",
                path: "/administration/categoriesandgenres",
              },
              {
                title: "Artists",
                path: "/administration/artists",
              },
              {
                title: "Providers",
                path: "/administration/providers",
              },
              {
                title: "Writers",
                path: "/administration/writers",
              },
              {
                title: "Publishers",
                path: "/administration/publishers",
              },
            ]}
          />
        </ul>
        <ul>
          <AdminVisibleWrapper>
            <NavbarSubMenu
              menuName="adminMenu"
              empty={true}
              mainLink={{
                title: "Admin",
                path: "/admin",
                icon: <NotesIcon />,
              }}
              linkList={[{ title: "Dev menu", path: "/devtools" }]}
            />
          </AdminVisibleWrapper>

          <li className="flex py-2 gap-x-4 items-center text-interactive hover:text-interactive-hover hover:bg-interactive-background rounded-lg cursor-pointer">
            <AvatarImage
              className="w-10 h-10"
              imageUrl={currentUser?.photoURL}
            />
            <span onClick={signOut}>Log out</span>
          </li>
        </ul>
      </div>
    </nav>
  );
});

export default Navbar;
