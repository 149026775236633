import {
  Column,
  ColumnDef,
  PaginationState,
  Row,
  SortingState,
  Table,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

import { Field, Form, Formik } from "formik";
import { ReactNode, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { PageableResponse } from "../../types/Types";
import {
  BackIcon,
  DownloadIcon,
  FastBackIcon,
  FastForwardIcon,
  ForwardIcon,
} from "../../utils/Icons";
import _ from "lodash";
import exportTableCSV from "../../utils/csvTableExporter/csvTableExporter";

interface GeneralTableProps<T extends object> {
  onRowClick?: (row: Row<T>) => void;
  showPagination?: boolean;
  data?: PageableResponse<T> | T[];
  columns: ColumnDef<T, any>[];
  className?: string;
  manualPagination?: boolean;
  filtering?: boolean;
  initialSorting?: SortingState;
  columnVisibility?: VisibilityState;
  disableExport?: boolean;
}

function getInitialPaginationState<T>(
  data: PageableResponse<T> | T[]
): PaginationState {
  if ("pageable" in data) {
    return {
      pageIndex: data.pageable.pageNumber || 0,
      pageSize: data.pageable.pageSize || 20,
    };
  }
  return {
    pageIndex: 0,
    pageSize: 20,
  };
}

function deriveData<T extends object>(data: PageableResponse<T> | T[]): T[] {
  if ("content" in data) {
    return data.content;
  }
  return data;
}

function getTotalPages<T>(data: PageableResponse<T> | T[]): number {
  if ("totalPages" in data) {
    return data.totalPages;
  }

  return _.ceil(data.length / 20);
}

function Filter({
  column,
  table,
}: {
  column: Column<any, any>;
  table: Table<any>;
}) {
  const firstValue = table
    .getPreFilteredRowModel()
    .flatRows[0]?.getValue(column.id);

  const columnFilterValue = column.getFilterValue();

  return typeof firstValue === "number" ? (
    <div
      className="flex space-x-2 text-neutral-semiheavy"
      onClick={(e) => e.stopPropagation()}
    >
      <input
        type="number"
        value={(columnFilterValue as [number, number])?.[0] ?? ""}
        onChange={(e) =>
          column.setFilterValue((old: [number, number]) => [
            e.target.value,
            old?.[1],
          ])
        }
        placeholder="Min"
        className="w-full rounded-md mt-2 p-2 bg-white focus:outline-none focus:border-transparent text-neutral-semiheavy"
      />
      <input
        type="number"
        value={(columnFilterValue as [number, number])?.[1] ?? ""}
        onChange={(e) =>
          column.setFilterValue((old: [number, number]) => [
            old?.[0],
            e.target.value,
          ])
        }
        placeholder="Max"
        className="w-full rounded-md mt-2 p-2 bg-white focus:outline-none focus:border-transparent text-neutral-semiheavy"
      />
    </div>
  ) : (
    <input
      className="w-full rounded-md mt-2 p-2 bg-white focus:outline-none focus:border-transparent text-sm text-neutral-semiheavy"
      onChange={(e) => column.setFilterValue(e.target.value.trimStart())}
      onClick={(e) => e.stopPropagation()}
      placeholder="Search..."
      type="text"
      value={(columnFilterValue ?? "") as string}
    />
  );
}

const GeneralTable = <T extends object>(props: GeneralTableProps<T>) => {
  const {
    onRowClick,
    showPagination,
    className,
    data = [],
    columns,
    manualPagination = false,
    filtering,
    initialSorting,
    disableExport = false,
  } = props;

  const [sorting, setSorting] = useState<SortingState>(initialSorting || []);
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>(
    getInitialPaginationState(props.data || [])
  );
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(
    props.columnVisibility || {}
  );

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const table = useReactTable<T>({
    data: deriveData(data) || [],
    columns: columns,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onPaginationChange: setPagination,
    onColumnVisibilityChange: setColumnVisibility,
    manualPagination: manualPagination,
    pageCount: getTotalPages(data),
    state: {
      pagination,
      sorting,
      columnVisibility,
    },
  });

  return (
    <div className={className}>
      <table className="w-full divide-y divide-gray-200 table-fixed">
        <thead className="font-semibold px-3 bg-neutral-trim">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className="p-2 py-4 first:rounded-tl-xl last:rounded-tr-xl text-left text-neutral uppercase tracking-wider align-center"
                >
                  {header.isPlaceholder ? null : (
                    <div
                      className={
                        header.column.getCanSort()
                          ? "cursor-pointer select-none"
                          : ""
                      }
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      {{
                        asc: <i className="fas fa-sort-up pl-2 items-center" />,
                        desc: (
                          <i className="fas fa-sort-down pl-2 items-center" />
                        ),
                      }[header.column.getIsSorted() as string] ?? null}
                    </div>
                  )}
                  {header.column.getCanFilter() && filtering ? (
                    <Filter column={header.column} table={table} />
                  ) : null}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="divide-y divide-neutral-trim">
          {table.getRowModel().rows.map((row) => (
            <tr
              key={row.id}
              className="cursor-pointer hover:bg-neutral-background"
              onClick={(e) => onRowClick && onRowClick(row)}
            >
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="relative h-16 bg-neutral-trim rounded-b-xl px-3 flex items-center justify-end">
        {showPagination && <Pagination table={table} />}

        {!disableExport && (
          <button className="secondary" onClick={() => exportTableCSV(table)}>
            <DownloadIcon />
          </button>
        )}
      </div>
    </div>
  );
};

export default GeneralTable;

interface PaginationProps<T> {
  table: Table<T>;
}

const Pagination = <T extends object>(props: PaginationProps<T>) => {
  const {
    getCanNextPage,
    getCanPreviousPage,
    getPageCount,
    previousPage,
    nextPage,
    getState,
    setPageIndex,
    firstPage,
    lastPage,
    getFilteredRowModel,
  } = props.table;

  const { manualPagination } = props.table.options;

  let [searchParams] = useSearchParams();
  const location = useLocation();

  const navigate = useNavigate();

  const filteredPageCount = manualPagination
    ? getPageCount()
    : _.ceil(getFilteredRowModel().rows.length / 20);

  const getURLIfSearchQueryProvided = () => {
    const param = searchParams.get("search");
    return param !== null ? `&search=${param}` : "";
  };

  return (
    <div className="absolute flex left-1/2 transform -translate-x-1/2 px-7 py-2 bg-white rounded-full">
      <PaginationButton
        isDisabled={!getCanPreviousPage()}
        onClick={() => {
          firstPage();
          navigate(location.pathname);
        }}
        icon={<FastBackIcon />}
      />

      <PaginationButton
        isDisabled={!getCanPreviousPage()}
        onClick={() => {
          previousPage();
          navigate(
            `${location.pathname}?page=${
              getState().pagination.pageIndex
            }${getURLIfSearchQueryProvided()}`
          );
        }}
        icon={<BackIcon />}
      />

      <div className="text-center flex gap-x-2 justify-center items-center">
        <Formik
          initialValues={{ page: getState().pagination.pageIndex + 1 }}
          enableReinitialize
          onSubmit={(e) => {
            navigate(
              `${location.pathname}?page=${
                e.page
              }${getURLIfSearchQueryProvided()}`
            );
            setPageIndex(e.page - 1);
          }}
        >
          {({ handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <Field
                type="text"
                className="border rounded-md focus:outline-none w-10 text-center"
                name="page"
              />
            </Form>
          )}
        </Formik>
        of {filteredPageCount}
      </div>
      <PaginationButton
        isDisabled={!getCanNextPage()}
        onClick={() => {
          nextPage();
          navigate(
            `${location.pathname}?page=${
              getState().pagination.pageIndex + 2
            }${getURLIfSearchQueryProvided()}`
          );
        }}
        icon={<ForwardIcon />}
      />
      <PaginationButton
        isDisabled={!getCanNextPage()}
        onClick={() => {
          navigate(
            `${
              location.pathname
            }?page=${getPageCount()}${getURLIfSearchQueryProvided()}`
          );
          lastPage();
        }}
        icon={<FastForwardIcon />}
      />
    </div>
  );
};

const PaginationButton = (props: {
  isDisabled: boolean;
  onClick: () => void;
  icon: ReactNode;
}) => {
  const { isDisabled, onClick } = props;
  return (
    <span
      className={`text-interactive ${
        isDisabled
          ? "text-neutral-light"
          : "hover:text-interactive-hover cursor-pointer"
      }`}
      onClick={() => {
        if (!isDisabled) {
          onClick();
        }
      }}
    >
      {props.icon}
    </span>
  );
};
