import React from "react";
import { useSelector } from "react-redux";
import { selectBlobStatusState } from "../redux/blob/selectors";
import ProgressBar from "./ProgressBar";

interface BlobStatusProps {
  url: string;
  doneCallback?: () => void;
  noBar?: boolean;
}

const BlobStatus = (props: BlobStatusProps) => {
  const { url, doneCallback, noBar } = props;
  const state = useSelector(selectBlobStatusState());

  if (doneCallback && state[url] === 100) {
    doneCallback();
  }

  return <ProgressBar noBar={noBar?.valueOf()} progress={state[url]} />;
};

export default BlobStatus;
