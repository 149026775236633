import { SoundWave } from "../utils/Icons";

const Loader = (props: {
  white?: boolean;
  text?: string;
  left?: boolean;
  large?: boolean;
  simple?: boolean;
}) => {
  if (props.simple) {
    return (
      <div>
        <SoundWave />
      </div>
    );
  }

  return (
    <div
      className={`flex items-center ${props.left ? null : "justify-center"}  ${
        props.large ? "w-16" : "w-5"
      }`}
    >
      {props.text ? <p className="mr-1 ">{props.text} </p> : null}
      <SoundWave />
    </div>
  );
};

export default Loader;
