import { useApolloClient } from "@apollo/client";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setProduct as setProductAction,
  setTracks,
} from "../redux/product/actions";
import { selectProductState } from "../redux/product/selectors";
import { Product } from "../types/ProductTypes";
import { Track } from "../types/TrackTypes";
import {
  GET_PRODUCT_QUERY,
  UPSERT_PRODUCTS_MUTATION,
} from "../utils/GraphQLQueries/ProductTrackQueries";
import {
  createThumbnail,
  getCoverArtBlobURL,
  getImageDimension,
  getThumbnailBlobURL,
  omitDeep,
  toProductInput,
} from "../utils/Helpers";
import { UploadInformation, useBlobUploader } from "../utils/useBlobUploader";
import useMessage from "./useMessage";

const useProduct = () => {
  const apolloClient = useApolloClient();
  const [product, setProduct] = useState<Product | undefined>(undefined);
  const { showErrorMessage, showSuccessMessage } = useMessage();
  const dispatch = useDispatch();
  const { uploadRecursive } = useBlobUploader();

  const productState = useSelector(selectProductState());

  const getProduct = (
    upc: string,
    useReduxGlobalState: Boolean = false,
    shouldFailSilently: boolean = false
  ) =>
    apolloClient
      .query<{ product: Product; tracksOnProduct: Track[] }>({
        query: GET_PRODUCT_QUERY,
        fetchPolicy: "no-cache",
        variables: { upc: parseInt(upc) },
      })
      .then((result) => {
        if (result.data.product) {
          setProduct(result.data.product);
          if (useReduxGlobalState) {
            dispatch(setProductAction(result.data.product));
            dispatch(setTracks(result.data.tracksOnProduct));
          }
        }
        return result.data.product;
      })
      .catch((err) => {
        if (!shouldFailSilently) {
          showErrorMessage("Metadata error", err.message);
        }
        throw err;
      });

  /**
   * Saves the cover art to the blob storage and updates the product with the cover art URL.
   * @param upc the upc of the product
   * @param image reference to the image file
   */
  const saveCoverArt = async (upc: number, image: File) => {
    const thumbnail = await createThumbnail(upc, image);
    const thumbnailURL = getThumbnailBlobURL(upc);
    const coverArtURL = getCoverArtBlobURL(upc);
    const coverArtDimension = (await getImageDimension(image)).toString();

    const coverArtUploadInfo: UploadInformation = {
      file: image,
      url: coverArtURL,
      metadata: {
        cacheControl: "public,max-age=2592000", //30 days cache in browser
        customMetadata: {
          dimension: coverArtDimension,
        },
      },
    };

    const thumbnailUploadInfo: UploadInformation = {
      file: thumbnail,
      url: thumbnailURL,
      metadata: {
        cacheControl: "public,max-age=2592000", //30 days cache in browser
        customMetadata: {
          dimension: coverArtDimension,
        },
      },
    };

    uploadRecursive([coverArtUploadInfo, thumbnailUploadInfo])
      .then(() => {
        showSuccessMessage("Cover Art", "Cover art saved successfully!");
      })
      .catch((err) => {
        showErrorMessage(`Cover Art`, `Cover art failed to save!`);
        throw err;
      });
  };

  const saveProduct = (product: Product) => {
    /**
    There is a bug in the Apollo Client that causes it to crash when it encounters a __typename property in the object being sent to the server.  This is a workaround until the bug is fixed.

    ts-ignore is there because most of the times the product object will be a Product type, but sometimes it will carry the __typename property when it is prefetched from the server.
    */
    //@ts-ignore
    product = omitDeep(product, "__typename");
    const productInput = toProductInput(product);

    apolloClient
      .mutate<Product[]>({
        mutation: UPSERT_PRODUCTS_MUTATION,
        variables: {
          products: [productInput],
        },
      })
      .then((res) => {
        showSuccessMessage(`Products`, `Products saved successfully!`);
      })
      .catch((err) => {
        showErrorMessage(`Failed`, `Products failed to save!`);
        throw err;
      });
  };

  return { productState, product, getProduct, saveProduct, saveCoverArt };
};

export default useProduct;
