import { createSelector, Selector } from "reselect";
import { Product } from "../../types/ProductTypes";
import { Track } from "../../types/TrackTypes";
import { ProductState } from "./reducers";

export const selectProductState = (): Selector<
  { product: ProductState },
  {
    product: Product;
    tracks: Track[];
    error?: Error;
  }
> =>
  createSelector(
    ({ product }) => product,
    (state) => ({
      product: state.product,
      tracks: state.tracks,
      error: state.error,
    })
  );
