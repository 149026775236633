import { createColumnHelper } from "@tanstack/react-table";
import { Track } from "../../../types/TrackTypes";
import moment from "moment";
import CopyString from "../../forms/trackform/CopyString";

const columnHelper = createColumnHelper<Track>();

export const TRACK_TABLE_COLUMNS = [
  columnHelper.accessor("isrc", {
    header: "Name",
    cell: (data) => (
      <div className="text-regular-body">
        <CopyString text={data.getValue() || ""} />
      </div>
    ),
  }),
  columnHelper.accessor("title", {
    header: "Title",
    cell: (data) => <p className="py-3 text-regular-body">{data.getValue()}</p>,
  }),
  columnHelper.accessor(
    (row) => row.primaryArtists.map((a) => a.artistName).join("; "),
    {
      id: "artists",
      header: "Artist",
      cell: (data) => (
        <p className="py-3 text-regular-body">{data.getValue()}</p>
      ),
    }
  ),
  columnHelper.accessor((row) => moment(row.createdAt).format("YYYY-MM-DD"), {
    id: "createdAt",
    header: "Creation Date",
    cell: (data) => data.getValue(),
  }),
  columnHelper.accessor(
    (row) => row.publishers?.map((w) => w.writer.writerName).join("; "),
    {
      id: "writers",
      header: "Writers",
      cell: (data) => (
        <p className="py-3 text-regular-body">{data.getValue()}</p>
      ),
    }
  ),
  columnHelper.accessor(
    (row) => row.publishers?.map((p) => p.publisher.publisherName).join("; "),
    {
      id: "publishers",
      header: "Publishers",
      cell: (data) => (
        <p className="py-3 text-regular-body">{data.getValue()}</p>
      ),
    }
  ),
  columnHelper.accessor("audioLanguage", {
    header: "Audio Language",
    cell: (data) => <p className="py-3 text-regular-body">{data.getValue()}</p>,
  }),
  columnHelper.accessor((row) => (row.isCover ? "Yes" : "No"), {
    id: "isCover",
    header: "Cover",
    cell: (data) => <p className="py-3 text-regular-body">{data.getValue()}</p>,
  }),
];
