import React, { useEffect, useState } from "react";
import { blobToFile } from "../utils/Helpers";

interface AvatarImageProps {
  imageUrl: string | undefined | null;
  className?: string;
}

const AvatarImage = React.memo((props: AvatarImageProps) => {
  const { imageUrl, className } = props;
  const [userAvatar, setuserAvatar] = useState<string>();

  useEffect(() => {
    if (imageUrl) {
      fetch(imageUrl)
        .then((res) => res.blob())
        .then((blob) => blobToFile(blob, "avatar.image"))
        .then((file) => {
          setuserAvatar(window.URL.createObjectURL(file));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  return (
    <div
      className={`${
        className ? className : "w-16 h-16"
      } rounded-full flex items-center justify-center`}
    >
      {userAvatar ? (
        <img className="rounded-full" alt="" src={userAvatar} />
      ) : (
        <i className="fas fa-user" />
      )}
    </div>
  );
});

export default AvatarImage;
