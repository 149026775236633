import axiosClient from "../clients/AxiosClient";
import { Publisher, Writer } from "../types/TrackTypes";
import { useQuery } from "@tanstack/react-query";

const usePublishers = (publisherId?: number, search?: string) => {
  const {
    data,
    refetch,
    isLoading: publishersLoading,
    error: publishersError,
  } = useQuery({
    queryKey: ["allPublishers", search],
    queryFn: () =>
      axiosClient
        .get<Publisher[]>("/publishers", {
          params: { search: search },
        })
        .then((res) => res.data),
    enabled: !publisherId,
  });

  const {
    data: publisher,
    refetch: refetchPublisher,
    isLoading,
  } = useQuery({
    queryKey: ["fetchPublisherById", publisherId],
    queryFn: () =>
      axiosClient
        .get<Publisher>(`/publishers/${publisherId}`)
        .then((res) => res.data),
    enabled: !!publisherId,
  });

  const {
    data: writersByPublisher,
    error: writersByPublisherError,
    isLoading: isLoadingWriters,
  } = useQuery({
    queryKey: ["writersByPublisher", publisherId],
    queryFn: () =>
      axiosClient
        .get<Writer[]>(`/publishers/${publisherId}/writers`)
        .then((res) => res.data),
    enabled: !!publisherId,
  });

  return {
    publishers: data,
    publishersLoading,
    publishersError,
    refetch,
    publisher,
    refetchPublisher,
    isLoading,
    writersByPublisher,
    writersByPublisherError,
    isLoadingWriters,
  };
};

export default usePublishers;
