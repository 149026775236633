import { useEffect, useState } from "react";
import { Link, Location, useLocation } from "react-router-dom";
import { NavbarLinkProps } from "./NavbarSubMenu";

export const NavbarListItem = (props: {
  link: NavbarLinkProps;
  innerLinkClassName?: string;
}) => {
  const { link, innerLinkClassName } = props;

  const location = useLocation();
  const [isActive, setisActive] = useState(false);

  useEffect(() => {
    setisActive(amIActiveAsMenuLink(location, props.link.path));
  }, [location]);

  return (
    <li
      className={`text-interactive hover:text-interactive-hover ${
        isActive ? "font-semibold" : ""
      } `}
    >
      <Link
        className={`flex gap-x-4 w-full rounded-lg mb-2 p-2 first:mb-0 ${
          innerLinkClassName
            ? innerLinkClassName
            : "hover:bg-interactive-background"
        }`}
        tabIndex={0}
        to={link.path}
        title={link.title}
      >
        {link.icon && <span>{link.icon}</span>}
        {link.title}
      </Link>
    </li>
  );
};

export const amIActiveAsMenuLink = (
  location: Location,
  path: string,
  exact?: boolean
) => {
  const paths = location.pathname.split("/");
  const toURIParts = path.split("/");

  if (exact) {
    return (
      paths.length === toURIParts.length &&
      paths.every((v, i) => v === toURIParts[i])
    );
  }

  if (location.pathname.includes(path)) {
    return true;
  }

  return paths[paths.length - 1] === toURIParts[toURIParts.length - 1];
};
