import moment from "moment-timezone";
import React from "react";
import { Product } from "../../../types/ProductTypes";

export interface AuthorsProps {
  product: Product;
}
const AuthorsDisplay = React.memo((props: AuthorsProps) => {
  const { authors, modifiedAt, createdAt } = props.product;

  return (
    <div className="flex gap-x-4 text-overline">
      <Author
        title="CREATED BY"
        name={authors?.createdBy.employeeName}
        time={moment(createdAt).tz("Europe/Stockholm").format("YYYY-MM-DD HH:mm")}
      />

      {authors?.modifiedBy && moment(modifiedAt).format("YYYY-MM-DD") !== moment(createdAt).format("YYYY-MM-DD") && (
        <Author title="MODIFIED BY" name={authors?.modifiedBy?.employeeName} time={modifiedAt} />
      )}
    </div>
  );
});

export default AuthorsDisplay;

export const Author = (props: { title: string; name: string | undefined; time?: string | undefined }) => {
  const time = moment(props.time).tz("Europe/Stockholm").format("YYYY-MM-D HH:mm");
  const { title, name } = props;

  return (
    <div className="flex flex-col font-light text-overline">
      <span className="text neutral-semiheavy">{title}</span>
      <span className="text neutral-semiheavy">{name}</span>
      {props.time && <span className="text neutral-semiheavy">{time}</span>}
    </div>
  );
};
