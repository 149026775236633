import { Action } from "redux";
import { isType } from "typescript-fsa";
import { emptyProduct } from "../../components/forms/productform/ProductForm";
import { Product } from "../../types/ProductTypes";
import { Track } from "../../types/TrackTypes";
import * as actions from "./actions";

export interface ProductState {
  product: Product;
  tracks: Track[];
  error?: Error;
}

const initialState: ProductState = {
  product: emptyProduct,
  tracks: [],
};

function swapElements<T>(list: T[], index1: number, index2: number): T[] {
  var b = list[index2];
  list[index2] = list[index1];
  list[index1] = b;
  return [...list];
}

function reducer(
  state: ProductState = initialState,
  action: Action
): ProductState {
  if (isType(action, actions.resetProductForm)) {
    state.tracks = [];
    state.product = emptyProduct;
    return { ...state };
  }

  if (isType(action, actions.setTracks)) {
    return { ...state, tracks: action.payload };
  }

  if (isType(action, actions.setProduct)) {
    return { ...state, product: { ...action.payload } };
  }

  if (isType(action, actions.addTrackToProduct)) {
    const ts = [...state.tracks];
    ts.push(action.payload);

    return { ...state, tracks: ts };
  }
  if (isType(action, actions.saveTrackInfo)) {
    state.tracks[action.payload.index] = action.payload.track;

    return { ...state };
  }

  if (isType(action, actions.removeTrackFromProduct)) {
    state.tracks.splice(action.payload, 1);
    return { ...state, tracks: [...state.tracks] };
  }

  if (isType(action, actions.swapTrackOrder)) {
    state.tracks = swapElements(
      state.tracks,
      action.payload.from,
      action.payload.to
    );
    return { ...state };
  }

  return state;
}

export default reducer;
