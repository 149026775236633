import { createSelector, Selector } from "reselect";
import { AudioPlayerState } from "./reducers";

export const selectAudioPlayerState = (): Selector<
  { audioPlayer: AudioPlayerState },
  { currentIsrc: string; currentUPC: string; isPlaying: boolean }
> =>
  createSelector(
    ({ audioPlayer }: { audioPlayer: AudioPlayerState }) => audioPlayer,
    (audioPlayer) => ({
      currentIsrc: audioPlayer.currentIsrc,
      currentUPC: audioPlayer.currentUPC,
      isPlaying: audioPlayer.isPlaying,
    })
  );
