import { ReactNode } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Role, useAuth } from "../auth/AuthProvider";
import { amIActiveAsMenuLink } from "../navbar/NavbarListItem";

const AwTab = (props: {
  to: string;
  children?: ReactNode;
  requiredRole?: Role;
  disabled?: boolean;
}) => {
  const { children, to, requiredRole, disabled } = props;
  const location = useLocation();
  const auth = useAuth();

  if (requiredRole && !auth.isAuthorizedTo(requiredRole)) return null;

  const activeCSS = amIActiveAsMenuLink(location, to, true)
    ? "border-interactive"
    : "border-transparent hover:border-interactive hover:text-interactive-hover hover:cursor-pointer";

  if (disabled) {
    return <li className="disabled py-2 px-4">{children}</li>;
  }

  return (
    <NavLink to={to}>
      <li className={`select-none border-b-2 ${activeCSS} py-2 px-4`}>
        {children}
      </li>
    </NavLink>
  );
};

export default AwTab;
