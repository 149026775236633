import { ReactNode } from "react";

export const InputFieldGroup = (props: {
  children: ReactNode;
  title?: string;
  error?: string;
}) => (
  <div className="mb-3">
    {props.title && (
      <h3 className="text-regular-body font-semibold text-neutral-semiheavy mb-3">
        {props.title}
      </h3>
    )}
    <div className="flex flex-col gap-y-2">{props.children}</div>
    {props.error && <p className="text-error">{props.error}</p>}
  </div>
);
