import { createContext, useContext, ReactNode, useState } from "react";
interface ModalProps {
  children: ReactNode;
  header?: string;
  className?: string;
  onClose?: () => void;
  onSave?: () => void;
}
const ModalContext = createContext<{
  onClose?: () => void;
}>({
  onClose: () => null,
});

export const useModalContext = () => useContext(ModalContext);

const Modal = (props: ModalProps) => {
  const { children, header, onClose, onSave, className } = props;
  const [isMouseDown, setisMouseDown] = useState(false);

  return (
    <div
      className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-50"
      onMouseUp={(e) => {
        if (isMouseDown) {
          if (props.onClose) props.onClose();
        }
      }}
      onMouseDown={() => {
        setisMouseDown(true);
      }}
    >
      <div
        className={`bg-white rounded-xl w-1/2 h-3/4 ${
          className ? className : null
        }`}
        onMouseDown={(e) => {
          e.stopPropagation();
        }}
        onMouseUp={(e) => e.stopPropagation()}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="text-lg border-b px-6">
          <div className="flex justify-between items-center my-2 py-4">
            {header && <span className="text-medium-title">{header}</span>}
            <div className="flex gap-x-2">
              {onSave && <button onClick={onSave}>Save</button>}
              {onClose && (
                <button className="neutral" onClick={onClose}>
                  Close
                </button>
              )}
            </div>
          </div>
        </div>
        <div
          className="flex justify-center bg-neutral-background max-h-screen-3/4 w-full h-full rounded-b-xl overflow-y-scroll p-6"
          onMouseDown={(e) => e.stopPropagation()}
          onMouseUp={(e) => e.stopPropagation()}
          onClick={(e) => e.stopPropagation()}
        >
          <ModalContext.Provider value={{ onClose }}>
            {children}
          </ModalContext.Provider>
        </div>
      </div>
    </div>
  );
};

export default Modal;
