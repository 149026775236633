import productReducer from "./product/reducers";
import audioPlayerReducer from "./audioplayer/reducers";
import globalMessagesReducer from "./globalmessages/reducers";
import blobReducer from "./blob/reducers";
import { combineReducers } from "redux";

export default combineReducers({
  product: productReducer,
  audioPlayer: audioPlayerReducer,
  globalMessages: globalMessagesReducer,
  blob: blobReducer,
});
