import { Selector } from "react-redux";
import { createSelector } from "reselect";

import { BlobStatusState } from "./reducers";

export const selectBlobStatusState = (): Selector<
  { blob: BlobStatusState },
  { [blobName: string]: number }
> =>
  createSelector(
    ({ blob }) => blob,
    (state) => ({ ...state.blobMap })
  );
