import { ChangeEvent, useRef } from "react";
import useMessage from "../../hooks/useMessage";
import useProduct from "../../hooks/useProduct";
import { UploadIcon } from "../../utils/Icons";
import React from "react";

interface UploadCoverArtButtonProps {
  upc: string;
  blobUrl: string | undefined;
  onImageLoad: (localImageUrl: string) => void;
}

const UploadCoverArtButton = (props: UploadCoverArtButtonProps) => {
  const { upc, onImageLoad } = props;
  const hiddenFileInput = useRef<HTMLInputElement | null>(null);

  const { showErrorMessage } = useMessage();
  const { saveCoverArt } = useProduct();

  const handleClick = () => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files as FileList;
    const file = fileList?.[0];
    if (file) {
      handleFile(file);
    }
  };

  const handleFile = (file: File) => {
    const img = new Image();
    img.onload = () => {
      //Check image size.
      if (img.width !== img.height || img.width < 2000 || img.width > 3200) {
        showErrorMessage(
          "Dimension Error",
          "Image dimensions incorrect! Must be square, 2000x2000 minimum, 3200x3200 maximum."
        );

        return;
      } else {
        saveCoverArt(parseInt(upc), file);
      }
    };
    const url = window.URL.createObjectURL(file);
    img.src = url;
    onImageLoad(url);
  };

  return (
    <>
      <button
        className={`flex cursor-pointer hover:text-white icon secondary group mr-2`}
        onClick={handleClick}
      >
        <UploadIcon />
      </button>
      <input
        ref={hiddenFileInput}
        className="hidden"
        id="artWork"
        type="file"
        name="coverArt"
        onChange={handleChange}
        style={{ display: "none" }}
      />
    </>
  );
};

export default UploadCoverArtButton;
