import { createColumnHelper } from "@tanstack/react-table";
import { Publisher } from "../../../types/TrackTypes";

const columnHelper = createColumnHelper<Publisher>();

export const PUBLISHER_TABLE_COLUMNS = [
  columnHelper.accessor("publisherName", {
    header: "Name",
    cell: (data) => (
      <p className="py-3 text-regular-body text-interactive hover:text-interactive-hover">
        {data.getValue()}
      </p>
    ),
  }),
];
