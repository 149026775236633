import actionCreatorFactory from "typescript-fsa";
import { Product } from "../../types/ProductTypes";
import { Track } from "../../types/TrackTypes";

const actionCreator = actionCreatorFactory();

export const addTrackToProduct = actionCreator<Track>("ADD_TRACK_TO_PRODUCT");

export const saveTrackInfo = actionCreator<{ index: number; track: Track }>(
  "SAVE_TRACK_INFO"
);

export const removeTrackFromProduct = actionCreator<number>(
  "REMOVE_TRACK_FROM_PRODUCT"
);

export const setProduct = actionCreator<Product>("SET_PRODUCT");
export const setTracks = actionCreator<Track[]>("SET_TRACKS");
export const addDelivery = actionCreator<string>("ADD_DELIVERY");
export const removeDelivery = actionCreator<string>("REMOVE_DELIVERY");

export const resetProductForm = actionCreator<void>("RESET_PRODUCT_FORM");
export const swapTrackOrder = actionCreator<{ from: number; to: number }>(
  "SWAP_TRACK_ORDER"
);
