import { ReactNode } from "react";

interface SectionsListProps {
  children: ReactNode;
  title?: string;
  className?: string;
  noBorder?: boolean;
}
const SectionsList = (props: SectionsListProps) => {
  const { children, title, className, noBorder } = props;
  return (
    <ul
      className={`mb-4 ${noBorder?.valueOf() ? "" : "border rounded-md border-gray-300"} ${className ? className : ""}`}
    >
      {title && <p className="text-md p-2 font-semibold  text-center">{props.title}</p>}
      {children}
    </ul>
  );
};

export default SectionsList;

export const SectionDivider = (props: { element?: ReactNode }) => {
  if (props.element) {
    return (
      <div className="flex">
        <span className="border-t w-full relative top-3 mr-4 border-gray-300" />
        {props.element}
        <span className="border-t w-full relative top-3 ml-4 border-gray-300" />
      </div>
    );
  }
  return <div className="w-full border-b border-gray-300" />;
};
export const Section = (props: {
  children: ReactNode;
  title?: string;
  description?: string;
  className?: string;
  outerClassName?: string;
}) => (
  <div className={props.outerClassName ? props.outerClassName : "p-6"}>
    {(props.title || props.description) && (
      <div className="flex flex-col mb-2">
        <span className="text-lg font-semibold ">{props.title}</span>

        {props.description ? <span className="font-light text-gray-400">{props.description}</span> : null}
      </div>
    )}
    <div className={props.className ? props.className : ""}>{props.children}</div>
  </div>
);

export const SectionListItem = (props: { children: ReactNode; noBorder?: boolean }) => {
  return (
    <div
      className={`p-2 ${
        props.noBorder?.valueOf() ? "" : "border-t"
      } border-gray-300  text-opacity-80 hover:bg-gray-100`}
    >
      {props.children}
    </div>
  );
};
