import axiosClient from "../clients/AxiosClient";
import { Track, Writer } from "../types/TrackTypes";
import { useQuery } from "@tanstack/react-query";

const useWriters = (writerId?: number, search?: string) => {
  const {
    data,
    refetch,
    isLoading: writersLoading,
    error: writersError,
  } = useQuery({
    queryKey: ["allWriters", search],
    queryFn: () =>
      axiosClient
        .get<Writer[]>("/writers", {
          params: { search: search },
        })
        .then((res) => res.data),
    enabled: !writerId,
  });

  const { data: writer, refetch: refetchWriter } = useQuery({
    queryKey: ["fetchWriterById", writerId],
    queryFn: () =>
      axiosClient.get<Writer>(`/writers/${writerId}`).then((res) => res.data),
    enabled: !!writerId,
  });

  const {
    data: writerTracks,
    isLoading: isLoadingTracks,
    error: writerTracksError,
  } = useQuery({
    queryKey: ["writerTracks", writerId],
    queryFn: () =>
      axiosClient
        .get<Track[]>(`/writers/${writerId}/tracks`)
        .then((res) => res.data),
    enabled: !!writerId,
  });

  return {
    writers: data,
    writersLoading,
    writersError,
    refetch,
    writer,
    refetchWriter,
    writerTracks,
    isLoadingTracks,
    writerTracksError,
  };
};

export default useWriters;
