import { Action } from "redux";
import { isType } from "typescript-fsa";
import * as actions from "./actions";

export interface BlobStatusState {
  blobMap: { [blobName: string]: number };
}

const initState = { blobMap: {} };
function reducer(
  state: BlobStatusState = initState,
  action: Action
): BlobStatusState {
  if (isType(action, actions.updateBlobStatus)) {
    state.blobMap[action.payload.blobName] = action.payload.progress;
    return { ...state };
  }

  return state;
}

export default reducer;
