import { FieldArrayRenderProps, useFormikContext } from "formik";
import React from "react";
import { Contributor, PublisherOnTrack, RoyaltyClaim } from "../../types/TrackTypes";
import { ContributorRole } from "../../config/Constants";
import { PlusIcon } from "../../utils/Icons";

interface RemoveFromListButtonProps {
  arrayHelpers: FieldArrayRenderProps;
  index: number;
}

export const RemoveFromListButton = (props: RemoveFromListButtonProps) => {
  const { arrayHelpers, index } = props;
  return (
    <button type="button" className="icon delete" onClick={() => arrayHelpers.remove(index)}>
      <i className="fas fa-trash-alt" />
    </button>
  );
};

interface AddTolistButtonProps<T> {
  arrayHelpers?: FieldArrayRenderProps;
  onClick?: () => void;
  emptyObject?: T;
}

export const AddTolistButton = <T,>(props: AddTolistButtonProps<T> & React.HTMLProps<HTMLButtonElement>) => {
  const { arrayHelpers, emptyObject, onClick, className } = props;
  return (
    <button
      type="button"
      className={`icon secondary ${className}`}
      onClick={() => {
        if (arrayHelpers) {
          arrayHelpers.push({ ...emptyObject });
        }
        if (onClick) {
          onClick();
        }
      }}
    >
      {<PlusIcon />}
    </button>
  );
};

export const EMPTY_CONTRIBUTOR: Contributor = {
  name: "",
  role: ContributorRole.COMPOSER,
  isAlias: false,
};

export const EMPTY_ROYALTY_CLAIM: RoyaltyClaim = {
  providerId: 0,
  royaltyRate: 0.0,
  isrc: "",
};

export const EMPTY_PUBLISHER_ON_TRACK: PublisherOnTrack = {
  isrc: "",
  publisher: {
    id: undefined,
    publisherName: "",
  },
  writer: {
    id: undefined,
    writerName: "",
  },
};

export const AutoSave = () => {
  // Grab values and submitForm from context
  const { values, submitForm } = useFormikContext();
  React.useEffect(() => {
    submitForm();
  }, [values, submitForm]);
  return null;
};

export const ValuesDebugger = () => {
  const { values } = useFormikContext();

  return <pre>{JSON.stringify(values, null, 2)}</pre>;
};
