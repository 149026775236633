import actionCreatorFactory from "typescript-fsa";
import { GlobalMessageComponent } from "./reducers";

const actionCreator = actionCreatorFactory();

export const addMessage =
  actionCreator<GlobalMessageComponent>("ADD_GLOBAL_MESSAGE");

export const closeMessage = actionCreator<string>("CLOSE_GLOBAL_MESSAGE");
export const deleteMessage = actionCreator<string>("DELETE_GLOBAL_MESSAGE");
