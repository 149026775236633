import { applyMiddleware, compose, createStore } from "redux";

import { createEpicMiddleware, combineEpics } from "redux-observable";
import rootReducer from "./index";

const epicMiddleware = createEpicMiddleware();

const options = {};

// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(options)
  : compose;

export const store = createStore(
  rootReducer,
  {},
  composeEnhancers(applyMiddleware(epicMiddleware))
);

const rootEpic = combineEpics();
epicMiddleware.run(rootEpic);
