import { Reducer } from "redux";

export interface AudioPlayerState {
  currentIsrc: string;
  currentUPC: string;
  isPlaying: boolean;
}

type AudioPlayerAction =
  | { type: "SET_ISRC"; payload?: string }
  | { type: "SET_UPC"; payload: string }
  | { type: "SET_IS_PLAYING"; payload: boolean }
  | { type: "PLAY_TRACK"; payload: { isrc: string; upc?: string } }
  | { type: "PAUSE_TRACK" };

const initialState: AudioPlayerState = {
  currentIsrc: "",
  currentUPC: "",
  isPlaying: false,
};

const reducer: Reducer<AudioPlayerState, AudioPlayerAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case "SET_ISRC":
      return { ...state, currentTrack: action.payload };
    case "SET_UPC":
      return { ...state, currentUPC: action.payload };
    case "SET_IS_PLAYING":
      return { ...state, isPlaying: action.payload };
    case "PLAY_TRACK":
      return {
        ...state,
        currentIsrc: action.payload.isrc,
        currentUPC: action.payload.upc || "",
        isPlaying: true,
      };
    case "PAUSE_TRACK":
      return { ...state, isPlaying: false };
    default:
      return state;
  }
};

export default reducer;
