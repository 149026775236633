import { ref } from "firebase/storage";
import _ from "lodash";
import moment from "moment-timezone";
import {
  coverArtStorage,
  legalDocumentsStorage,
  settlementStorage,
  invoiceAndStatementsStorage,
  trackStorage,
} from "../components/auth/firebase/firebase";
import {
  COVER_ART_BUCKET,
  LEGAL_DOCUMENTS_BUCKET,
  SETTLEMENTS_BUCKET,
  TRACKS_BUCKET,
  X5_DSP_CONFIGS,
} from "../config/Constants";
import {
  LintedFileMetadata,
  LintedMetadataValue,
  SemiLintedMetadata,
} from "../types/BulkTypes";
import { UploadInformation } from "./useBlobUploader";
import { SettlementRecipient, SettlementSender } from "../types/FinanceTypes";
import { Genre, QSProvider } from "../types/Types";
import { Product, ProductInput } from "../types/ProductTypes";
import { getValue } from "./BulkMetadataTools";
import JSZip from "jszip";
import FileSaver from "file-saver";

export function omitDeep<T extends { [key: string]: string | any }>(
  value: T,
  key: keyof T
): any {
  if (Array.isArray(value)) {
    return value.map((i) => omitDeep(i, key));
  } else if (typeof value === "object" && value !== null) {
    return Object.keys(value).reduce((newObject, k) => {
      if (k === key) return newObject;
      return Object.assign({ [k]: omitDeep(value[k], key) }, newObject);
    }, {});
  }
  return value;
}

export function getValueDeep<T>(obj: any, field: string): T {
  for (const key in obj) {
    if (key === field) return obj[key];
    if (typeof obj[key] === "object") {
      const value = getValueDeep(obj[key], field);
      if (value) return value as T;
    }
  }

  throw Error(`Could not find field ${field} in object ${obj}`);
}

export function notNullOrUndefined<TValue>(
  value: TValue | null | undefined
): value is TValue {
  return value !== null && value !== undefined;
}

export function isLintedMetadataType<TValue>(
  value: LintedMetadataValue<any> | TValue
): value is TValue {
  if (value === null || value === undefined) return false;
  return (value as LintedMetadataValue<TValue>).value !== undefined;
}

export function extractFromLinted<T>(object: SemiLintedMetadata<T>): T {
  return Object.assign(
    {},
    ...Object.entries(object).map(([key, value]) => ({
      [key]: getValue(value),
    }))
  );
}

export const getTrackDuration = (file: File | undefined): Promise<number> =>
  new Promise((res, rej) => {
    try {
      if (file === undefined) {
        res(0);
      }
      let audio = new Audio();
      audio.onloadedmetadata = function () {
        res(Number(audio.duration.toFixed(0)));
      };
      if (file !== undefined) {
        audio.src = URL.createObjectURL(file);
      }
    } catch (error) {
      //if file was not included
      res(0);
    }
  });

export const capitalizeFirstLetter = (str: string): string => {
  const lowercaseStr = str.toLowerCase();
  const capitalizedStr =
    lowercaseStr.charAt(0).toUpperCase() + lowercaseStr.slice(1);
  return capitalizedStr;
};

export const getConfigValue = (configValue: string) => {
  return X5_DSP_CONFIGS.find((config) => config.name === configValue)?.value;
};

export const getConfigName = (configName: string) => {
  return (
    X5_DSP_CONFIGS.find((config) => config.value === configName)?.name ||
    X5_DSP_CONFIGS[0].name
  );
};

export const blobToFile = (blob: Blob, fileName?: string): File => {
  let b: any = blob;
  b.lastModifiedDate = new Date();
  b.name = fileName;
  return b;
};

export function renameFile(originalFile: File, name: string): File {
  return new File([originalFile], name, {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  });
}

export function toProductInput(product: Product): ProductInput {
  return {
    upc: product.upc,
    catalogNumber: product.catalogNumber,
    cLineText: product.cLineText,
    cLineYear: product.cLineYear,
    pLineText: product.pLineText,
    pLineYear: product.pLineYear,
    label: product.label,
    languageOfMetadata: product.languageOfMetadata,
    mainGenre: product.mainGenre,
    subGenre: product.subGenre,
    name: product.name,
    originalReleaseDate: product.originalReleaseDate,
    primaryArtistIds: product.primaryArtists
      .map((a) => a.artistId)
      .filter(notNullOrUndefined),
    featuredArtistIds: product.featuredArtists
      .map((a) => a.artistId)
      .filter(notNullOrUndefined),
    productVersion: product.productVersion,
    releaseFormatType: product.releaseFormatType,
    tracks: product.tracks,
    spotifyProductSpecifics: product.spotifyProductSpecifics,
    releaseDates: product.releaseDates,
    dspSpecificLabels: product.dspSpecificLabels,
  };
}

export const getYearMonthFromDate = (
  date: Date
): { year: number; month: number } => {
  const dateParts = date.toISOString().split("T")[0].split("-");
  return { year: parseInt(dateParts[0]), month: parseInt(dateParts[1]) };
};

export const getZeroPrefixedNumberString = (number: number) =>
  number < 10 ? `0${number}` : number.toString();

export const toSemiSep = (list: string[]) => {
  if (list.length === 0) return "";
  if (list.length === 1) {
    return list[0];
  }

  return list.reduce((prev, curr) => `${prev};${curr}`);
};
export const fromSemiSep = (value: string) => value.split(";");

export const trimLowerLint = (value: string): string =>
  value
    .trim()
    .toLowerCase()
    .replace(/[^a-z0-9]/g, "");

export const toCommaSep = (list: string[]) => {
  if (list.length === 0) return "";
  if (list.length === 1) {
    return list[0];
  }
  return list.reduce((prev, curr) => `${prev},${curr}`);
};

export function isGenre(obj: any): obj is Genre {
  if ((obj as Genre).id < 0 || (obj as Genre).id === undefined) {
    return false;
  }

  return true;
}

export function isProduct(obj: any): obj is Product {
  return (obj as Product).upc !== undefined && (obj as Product).name !== "";
}

export const getTrackBlobURL = (isrc: string) => `${TRACKS_BUCKET}/${isrc}.wav`;

export const getAtmosBlobURL = (isrc: string) =>
  `${TRACKS_BUCKET}/atmos/${isrc}.wav`;

export const getCoverArtBlobURL = (upc: string | number) =>
  `${COVER_ART_BUCKET}/fullsize/${upc}.jpg`;

export const getThumbnailBlobURL = (upc: string | number) =>
  `${COVER_ART_BUCKET}/thumbnails/${upc}.jpg`;

export const getSettlementBlobURL = (fileName: string) =>
  `${SETTLEMENTS_BUCKET}/${fileName}`;

export const getLegalDocumentForContractBlobURL = (
  contractId: number,
  fileName: string
) => `${LEGAL_DOCUMENTS_BUCKET}/contracts/${contractId}/${fileName}`;

export function getStorageRefFromURI(uri: string) {
  const bucket = uri.split("/")[2];

  switch (bucket) {
    case "aw-tracks-bucket":
      return ref(trackStorage, uri);
    case "aw-cover-art-bucket":
      return ref(coverArtStorage, uri);
    case "aw-mgmt-finance-settlement-landing":
      return ref(settlementStorage, uri);
    case "aw-legal-documents-bucket":
      return ref(legalDocumentsStorage, uri);
    case "aw-statements-and-invoices":
      return ref(invoiceAndStatementsStorage, uri);
    default:
      return ref(trackStorage, uri);
  }
}

export const createThumbnails = async (lintedMetadata: {
  [fileName: string]: LintedFileMetadata;
}): Promise<File[]> => {
  const thumbnails: Promise<File>[] = [];

  Object.entries(lintedMetadata).forEach(([_, metadata]) => {
    thumbnails.push(
      new Promise((res, rej) => {
        let reader = new FileReader();
        reader.onerror = function (err) {
          console.error(err);
          rej();
        };
        reader.onload = function (event) {
          let img = new Image();
          img.onerror = function (err: any) {
            console.log(err);
            rej();
          };

          img.onload = function () {
            try {
              let canvas = document.createElement("canvas");
              canvas.width = img.width;
              canvas.height = img.height;
              canvas.getContext("2d")?.drawImage(img, 0, 0);

              let rescaledCanvas = document.createElement("canvas");

              rescaledCanvas.width = 128;
              rescaledCanvas.height = 128;
              rescaledCanvas
                .getContext("2d")
                ?.drawImage(
                  canvas,
                  0,
                  0,
                  rescaledCanvas.width,
                  rescaledCanvas.height
                );

              rescaledCanvas.toBlob(
                (blob) => {
                  res(blobToFile(blob!!, metadata.upc.value.toString()));
                },
                "image/jpeg",
                1 //Compression factor
              );
            } catch (error) {
              rej(error);
            }
          };

          img.src = event.target?.result as string;
        };

        if (metadata.coverArt?.value) {
          reader.readAsDataURL(metadata.coverArt.value.file);
        }
      })
    );
  });

  return await Promise.all(thumbnails).catch((err) => {
    return [];
  });
};

export const generateThumbnails2022 = async (
  listOfMetadata: LintedFileMetadata[]
): Promise<File[]> => {
  const uniquePerProducts = _.uniqBy(listOfMetadata, (m) => m.upc.value);

  const thumbnailsPromises = uniquePerProducts.map((meta) =>
    createThumbnail(meta.upc.value, meta.coverArt.value?.file!!)
  );
  return await Promise.all(thumbnailsPromises).catch((err) => {
    return [];
  });
};

/**
 * Creates a thumbnail from a full size picture
 *
 * @param upc upc for album
 * @param image the file containing the image
 * @returns
 */
export const createThumbnail = async (
  upc: number,
  image: Blob | File
): Promise<File> => {
  return new Promise((res, rej) => {
    let reader = new FileReader();
    reader.onerror = function (err) {
      console.error(err);
      rej();
    };
    reader.onload = function (event) {
      let img = new Image();
      img.onerror = function (err: any) {
        console.log(err);
        rej();
      };

      img.onload = function () {
        try {
          let canvas = document.createElement("canvas");
          canvas.width = img.width;
          canvas.height = img.height;
          canvas.getContext("2d")?.drawImage(img, 0, 0);

          let rescaledCanvas = document.createElement("canvas");

          rescaledCanvas.width = 256;
          rescaledCanvas.height = 256;
          rescaledCanvas
            .getContext("2d")
            ?.drawImage(
              canvas,
              0,
              0,
              rescaledCanvas.width,
              rescaledCanvas.height
            );

          rescaledCanvas.toBlob(
            (blob) => {
              res(blobToFile(blob!!, upc.toString()));
            },
            "image/jpeg",
            1 //Compression factor
          );
        } catch (error) {
          rej(error);
        }
      };

      img.src = event.target?.result as string;
    };

    reader.readAsDataURL(image);
  });
};

export const getImageDimension = async (file: File) =>
  new Promise<number>((res, rej) => {
    const fileUrl = window.URL.createObjectURL(file);

    const img = new Image();
    img.onload = function () {
      res(img.width);
    };
    img.src = fileUrl;
  });

/**
 * Makes it easier to display tracks related to each product after parsing and linting.
 *
 * @param lintedMetadata the linted metadata
 * @returns
 */
export const groupTracksPerProduct = (lintedMetadata: {
  [upcIsrc: string]: LintedFileMetadata;
}) => {
  const map: { [upc: string]: LintedFileMetadata[] } = {};

  Object.values(lintedMetadata).forEach((lintedMeta) => {
    map[lintedMeta.upc.value.toString()] = [];
  });
  Object.entries(lintedMetadata)
    .map(([_, lintedMeta]) => lintedMeta)
    .forEach((meta) => {
      map[meta.upc.value.toString()].push(meta);
    });

  return map;
};

/**
 * Creates a list of unique blobs with their metadata ready to be uploaded.
 *
 * @param metadataPerProduct
 */
export const createUniqueBlobList = async (metadataPerProduct: {
  [upc: string]: LintedFileMetadata[];
}) => {
  const listOfMetadata = Object.values(metadataPerProduct).flatMap((v) => v);
  const blobs: UploadInformation[] = _.flattenDeep(
    listOfMetadata.map((m) => {
      if (m.audio?.value?.file && m.coverArt?.value) {
        const blobList: UploadInformation[] = [
          {
            file: m.audio.value.file,
            url: getTrackBlobURL(m.isrc.value),
            metadata: {
              //Convert Numbers => String for google.
              customMetadata: Object.assign(
                {},
                ...Object.entries(m.audio.value.headers).map(([key, val]) => ({
                  [key]: val.toString(),
                }))
              ),
            },
          },
          {
            file: m.coverArt.value.file,
            url: getCoverArtBlobURL(m.upc.value.toString()),
            metadata: {
              customMetadata: {
                dimension: m.coverArt.value.dimension.toString(),
              },
            },
          },
        ];
        return blobList;
      }

      return [];
    })
  );

  await generateThumbnails2022(listOfMetadata)
    .then((thumbnails) =>
      thumbnails.map((thumbnail) => ({
        file: thumbnail,
        url: getThumbnailBlobURL(thumbnail.name), //thumbnail.name will be UPC from createThumbnails
      }))
    )
    .then((uploadInformation) => blobs.push(...uploadInformation));
  return _.uniqBy(blobs, (b) => b.url);
};

export const filterObject = <T>(
  obj: { [key: string]: T },
  filterKeys: string[]
) =>
  Object.fromEntries(
    new Map([
      ...Object.entries(obj).filter(([key, _]) => filterKeys.includes(key)),
    ])
  );

export function dateAndTime() {
  return moment().tz("Europe/Stockholm").format("yyyy-MM-DD_hhmm");
}

export function stockholmTime(date: string | undefined) {
  return moment(date).tz("Europe/Stockholm").format("yyyy-MM-DD HH:mm:ss");
}

export const sleep = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const enumToReactSelectOption = <
  T extends SettlementSender | SettlementRecipient
>(
  enumValue: T
): { value: string; label: string } => {
  return { value: enumValue, label: enumValue };
};

export const extractParentProviders = (providers: QSProvider[]) => {
  const parentIds = new Set(
    _.uniq(providers.filter((p) => p.parentId !== 0).map((p) => p.parentId))
  );
  return providers.filter((p) => parentIds.has(p.id));
};

export const convertToTitleCase = (title: string) => {
  const smallWords = /^(a|an|and|as|at|but|by|for|in|of|on|or|the|to|with)$/i;
  return title.replace(/\w\S*/g, (txt, offset) => {
    if (offset > 0 && smallWords.test(txt.toLowerCase())) {
      return txt.toLowerCase();
    }
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const isTitleValid = (title: string) => {
  if (title === convertToTitleCase(title)) {
    return true;
  }

  if (title === title.toLowerCase()) {
    return true;
  }

  if (title === title.toUpperCase()) {
    return true;
  }
  return false;
};

export const downloadFileToDisk = (file: File) => {
  const href = window.URL.createObjectURL(file);
  const link = document.createElement("a");
  link.download = file.name;
  link.href = href;
  document.body.appendChild(link);
  link.click();
  link.remove();
  window.URL.revokeObjectURL(href);
};

export function formatNumberByThousands(input: string | number): string {
  // Convert input to string if it's a number
  const numberString: string =
    typeof input === "number" ? input.toString() : input;

  // Split the number into integer and decimal parts (if any)
  const [integerPart, decimalPart] = numberString.split(".");

  // Add comma every three digits from the right in the integer part
  const formattedIntegerPart: string = integerPart.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ","
  );

  // If there is a decimal part, combine integer and decimal parts with a dot
  const formattedNumber: string = decimalPart
    ? `${formattedIntegerPart}.${decimalPart}`
    : formattedIntegerPart;

  return formattedNumber;
}

/**
 * Returns a list of lists grouped by a key
 * @param arr
 * @param key
 * @returns
 */
export function groupIntoListOfLists<T>(arr: T[], key: keyof T): T[][] {
  return _.values(_.groupBy(arr, (o) => o[key]));
}

export const parseOptionalInt = (
  value: string | undefined
): number | undefined => {
  if (value === undefined) return undefined;
  return parseInt(value);
};
export function isEmptyOrNullOrUndefined(
  targetString: string | null | undefined
): boolean {
  return (
    targetString == null ||
    targetString === undefined ||
    targetString.trim() === ""
  );
}

export const logJSON = (obj: any) => console.log(JSON.stringify(obj, null, 2));

export function getEnumKeyByValue(
  enumObj: any,
  value: string
): string | undefined {
  return Object.keys(enumObj).find((key) => enumObj[key] === value);
}

export function getEnumValueByKey(
  enumObj: any,
  key: string
): string | undefined {
  return enumObj[key];
}

/**
 * Validates an ISRC code.
 * returns true if the ISRC is valid
 * returns false if the ISRC is invalid
 * @param isrc
 * @returns boolean
 */
export function validateISRC(isrc: string): boolean {
  const regexPattern = /^[A-Z]{2}-?\w{3}-?\d{2}-?\d{5}$/;
  if (!regexPattern.test(isrc)) {
    return false;
  }
  return true;
}

export const saveFilesInZip = async (
  files: File[],
  folderName: string,
  zipName: string
) => {
  const zip = new JSZip();
  const folder = zip.folder(folderName);
  files.forEach((file) => folder?.file(file.name, file));

  zip.generateAsync({ type: "blob" }).then((blob) => {
    FileSaver.saveAs(blob, zipName);
  });
};
