import { initializeApp } from "firebase/app";
import {
  browserLocalPersistence,
  getAuth,
  setPersistence,
} from "firebase/auth";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBxDkqYrvKNFudur5F7uk4Msbs5i5IU9_8",
  authDomain: "aw-mgmt-app-prod-4fd03183.firebaseapp.com",
  projectId: "aw-mgmt-app-prod-4fd03183",
  storageBucket: "aw-mgmt-app-prod-4fd03183.appspot.com",
  messagingSenderId: "116238984891",
  appId: "1:116238984891:web:c3435ca2e99e869a756b9e",
};

const firebaseApp = initializeApp(firebaseConfig);
export const firebaseAuth = getAuth(firebaseApp);
setPersistence(firebaseAuth, browserLocalPersistence);

export const trackStorage = getStorage(firebaseApp, "gs://aw-tracks-bucket");
export const coverArtStorage = getStorage(
  firebaseApp,
  "gs://aw-cover-art-bucket"
);

export const settlementStorage = getStorage(
  firebaseApp,
  "gs://aw-settlements-bucket"
);

export const legalDocumentsStorage = getStorage(
  firebaseApp,
  "gs://aw-legal-documents-bucket"
);

export const invoiceAndStatementsStorage = getStorage(
  firebaseApp,
  "gs://aw-invoice-and-statements-bucket"
);

export const CLIENT_ID =
  "116238984891-pko320re2d97qr7u9f7fdr4lcn4q2oao.apps.googleusercontent.com";

export default firebaseApp;
