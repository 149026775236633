import React, { useState } from "react";
import copyIcon from "../../../assets/icons/copy_icon.svg";

interface CopyToClipboardTextProps {
  text: string;
  listOfStrings?: string[];
  className?: string;
}

const CopyString = (props: CopyToClipboardTextProps) => {
  const { text, listOfStrings, className } = props;
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = () => {
    const textToCopy = listOfStrings ? listOfStrings.join("\n") : text;
    navigator.clipboard.writeText(textToCopy).then(() => {
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 1000); // Reset copied state after 1 seconds
    });
  };

  return (
    <div className="flex group">
      <img
        className={`mr-1 opacity-0 group-hover:opacity-100 transition-opacity duration-150 ease-in ${className}`}
        src={copyIcon}
        alt="copy icon"
      />
      <span
        className="cursor-pointer  group-hover:text-interactive"
        onClick={(e) => {
          e.stopPropagation();
          copyToClipboard();
        }}
      >
        {isCopied ? (
          <span className={`text-interactive transistion-opacity ${className}`}>
            Copied!
          </span>
        ) : (
          <span
            className={`inline-block transform -translate-x-5 transition-transform group-hover:translate-x-0 ${className}`}
          >
            {text}
          </span>
        )}
      </span>
    </div>
  );
};

export default CopyString;
