import { useState } from "react";
import ContentWrapper from "../../ContentWrapper";
import { AwSection } from "../../awcomponents/AwSection";
import GeneralTable from "../../table/GeneralTable";
import { useNavigate } from "react-router";
import Modal from "../../modal/Modal";
import useWriters from "../../../hooks/useWriters";
import WriterForm from "../../forms/writerform/WriterForm";
import { WRITER_TABLE_COLUMNS } from "../../table/models/WriterTableColumns";

const WritersAdministration = () => {
  const { writers, refetch, writersLoading, writersError } = useWriters();
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);

  return (
    <ContentWrapper title="Writers">
      {showModal && (
        <Modal header="New artist" onClose={() => setShowModal(false)}>
          <WriterForm
            writer={undefined}
            onSubmitCB={() => {
              refetch();
              setShowModal(false);
            }}
          />
        </Modal>
      )}
      <AwSection
        isLoading={writersLoading}
        error={writersError}
        action={
          <button
            className="secondary small"
            onClick={() => setShowModal(true)}
          >
            Add new writer
          </button>
        }
      >
        <GeneralTable
          onRowClick={(row) => {
            navigate(`/administration/writers/${row.original.id}`);
          }}
          columns={WRITER_TABLE_COLUMNS}
          data={writers}
          filtering
          showPagination
        />
      </AwSection>
    </ContentWrapper>
  );
};

export default WritersAdministration;
