import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Loader from "../Loader";
import Message from "../message/Message";
import { addMessage, closeMessage } from "../../redux/globalmessages/actions";
import { getCoverArtBlobURL } from "../../utils/Helpers";
import { useFileDownloader } from "../../utils/useFileDownloader";
import { ReactComponent as DownloadIcon } from "../../assets/icons/download_icon.svg";
import UploadCoverArtButton from "../buttons/UploadCoverArtButton";

interface CoverArtDisplayProps {
  upc: string;
  size?: string;
  noControls?: boolean;
  roundedLeftCorners?: boolean;
}

const CoverArtDisplay = (props: CoverArtDisplayProps) => {
  const dispatch = useDispatch();
  const { upc, roundedLeftCorners = true } = props;
  const [coverArt, setcoverArt] = useState<string>();
  const [coverArtDimension, setcoverArtDimension] = useState<number>();
  const [fetchingCoverArt, setfetchingCoverArt] = useState(true);

  const { downloadFile, checkFileExist } = useFileDownloader({
    useGlobalState: false,
  });

  const getImageDimensionFromURL = (url: string) =>
    new Promise<number>((res, rej) => {
      const img = new Image();
      img.onload = function () {
        res(img.width);
      };
      img.src = url;
    });

  useEffect(() => {
    const blobUrl = getCoverArtBlobURL(upc);

    checkFileExist(blobUrl).then((isFileExisting) => {
      if (isFileExisting) {
        downloadFile(getCoverArtBlobURL(upc), `${upc}.jpg`)
          .then((file) => {
            setfetchingCoverArt(false);
            const fileUrl = window.URL.createObjectURL(file);
            setcoverArt(fileUrl);
            getImageDimensionFromURL(fileUrl).then(setcoverArtDimension);
          })
          .catch((err) => {
            setfetchingCoverArt(false);
            dispatch(
              addMessage({
                id: `cover-art-error-${upc}`,
                displayTime: 3000,
                component: (
                  <Message
                    type="error"
                    message="Could not fetch cover art file"
                    onClose={() =>
                      dispatch(closeMessage(`cover-art-error-${upc}`))
                    }
                  />
                ),
              })
            );
          });
      } else {
        setcoverArt(undefined);
        setfetchingCoverArt(false);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [upc]);

  if (fetchingCoverArt) {
    return (
      <div className="col-2 flex justify-center items-center w-96 bg-neutral-background">
        <Loader large />
      </div>
    );
  }
  return (
    <div className="flex flex-col group">
      <div className="relative brightness-100">
        {coverArt && (
          <>
            <img
              className={`${
                roundedLeftCorners && "rounded-l-xl"
              } group-hover:brightness-50 ease-in duration-200 h-full object-cover`}
              alt=""
              src={coverArt}
              width={props.size || 365}
              height={props.size || 365}
            />
            {props.noControls === undefined && (
              <div className="transition-all opacity-0 -translate-x-5 group-hover:opacity-100 group-hover:translate-x-0 duration-200 absolute bottom-5 left-5 flex items-left rounded-md">
                <div className="text-white bottom-20 left-20 ">
                  {coverArtDimension && (
                    <div className="mb-4">
                      <p className="text-overline">SIZE</p>
                      <p className="text-regular-body-bold">
                        {coverArtDimension} px
                      </p>
                    </div>
                  )}
                  <div className="flex">
                    <button
                      className="flex cursor-pointer hover:text-white secondary icon group mr-2"
                      onClick={() => {
                        downloadFile(
                          getCoverArtBlobURL(upc),
                          `${upc}.jpg`,
                          true
                        );
                      }}
                    >
                      {<DownloadIcon />}
                    </button>

                    <UploadCoverArtButton
                      upc={upc}
                      blobUrl={coverArt}
                      onImageLoad={(url) => {
                        setcoverArt(url);
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </>
        )}
        {!coverArt && (
          <>
            <img
              className="shadow-md rounded-md group-hover:brightness-50 ease-in duration-200 grayscale"
              alt=""
              src={require("../../assets/audiowell_icon_standard_512.png")}
              width={props.size || 400}
              height={props.size || 400}
            />
            {props.noControls === undefined && (
              <div className="transition-all opacity-0 -translate-x-5 group-hover:opacity-100 group-hover:translate-x-0 duration-200 absolute bottom-5 left-5 flex items-left rounded-md">
                <div className="text-white">
                  {coverArtDimension && (
                    <span className="text-3xl">{coverArtDimension} px</span>
                  )}
                  <UploadCoverArtButton
                    upc={upc}
                    blobUrl={coverArt}
                    onImageLoad={(url) => {
                      setcoverArt(url);
                    }}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default CoverArtDisplay;
