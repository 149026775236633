import { ReactNode, useEffect } from "react";
import { Tile } from "./Tile";
import "./message.scss";

export interface MessageProps {
  message?: ReactNode;
  messageTitle?: string;
  minimizedMessage?: boolean;
  auto?: boolean;
  onClose?: () => void;
  type: "error" | "warning" | "info" | "success";
  isWarning?: string;
}

const Message = (props: MessageProps) => {
  const { messageTitle, type, message, auto, onClose } = props;

  useEffect(() => {
    if (auto) {
      const timer = setTimeout(() => {
        if (onClose) {
          onClose();
        }
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [auto, onClose]);

  return <Tile content={message} messageTitle={messageTitle} type={type} popIn={true} />;
};

export default Message;
