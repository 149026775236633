const Check = (props: { value?: boolean; color?: string }) => {
  const { value, color } = props;

  if (value === undefined) {
    return <i className="fas fa-minus mt-1" />;
  }

  return value.valueOf() ? (
    <i className={`fas fa-check mt-1 ${color ? color : "text-success"}`} />
  ) : (
    <i className={`fas fa-times mt-1 ${color ? color : "text-error"}`} />
  );
};

export default Check;
