import { useState } from "react";
import { useApolloClient } from "@apollo/client";
import { Track } from "../types/TrackTypes";
import {
  GET_FULL_TRACK_EXPENSIVE,
  GET_TRACKS_ON_PRODUCT_QUERY,
} from "../utils/GraphQLQueries/ProductTrackQueries";

const useTrack = () => {
  const apolloClient = useApolloClient();
  const [track, setTrack] = useState<Track>();

  const getTrack = async (isrc: string) => {
    try {
      await apolloClient
        .query<{ track: Track }>({
          query: GET_FULL_TRACK_EXPENSIVE,
          fetchPolicy: "no-cache",
          variables: { isrc: isrc },
        })
        .then((result) => {
          if (result.data.track) {
            setTrack(result.data.track);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  const getTracksFromUPC = async (upc: string) => {
    try {
      await apolloClient
        .query<{ tracks: Track[] }>({
          query: GET_TRACKS_ON_PRODUCT_QUERY,
          fetchPolicy: "no-cache",
          variables: { upc: upc },
        })
        .then((result) => {
          if (result.data.tracks) {
            return result.data.tracks;
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  return {
    track,
    getTrack,
    getTracksFromUPC,
  };
};

export default useTrack;
