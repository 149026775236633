import { Form, Formik } from "formik";
import useMessage from "../../../hooks/useMessage";
import { Writer } from "../../../types/TrackTypes";
import SimpleField from "../../SimpleField";
import * as Yup from "yup";
import axiosClient from "../../../clients/AxiosClient";
import useWriters from "../../../hooks/useWriters";

interface WriterFormProps {
  writer?: Writer;
  onSubmitCB?: (props?: any) => void;
}

const WriterForm = (props: WriterFormProps) => {
  const { showSuccessMessage, showErrorMessage } = useMessage();
  const { writers } = useWriters();
  const { writer, onSubmitCB } = props;

  const validationSchema = Yup.object().shape({
    writerName: Yup.string()
      .trim()
      .required("This field is required")
      .lowercase()
      .notOneOf(
        writers?.map((w: Writer) => w.writerName.toLowerCase()) || [],
        "Writer already exists"
      ),
  });
  return (
    <Formik<Writer>
      initialValues={writer || { writerName: "", id: undefined }}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={(values, helpers) => {
        axiosClient
          .post("/writers", values)
          .then(() => {
            showSuccessMessage("Success", "Writer saved successfully");
            onSubmitCB && onSubmitCB();
            helpers.resetForm();
          })
          .catch((error) => {
            showErrorMessage("Error", error.response.data.message);
          });
      }}
    >
      {({ dirty, isValid }) => (
        <Form className="justify-between gap-y-4">
          <div className="flex gap-8 items-center mb-8">
            <SimpleField
              className="w-full"
              type="string"
              placeholder="Writer name"
              name="writerName"
            />
          </div>
          <button type="submit" disabled={!dirty || !isValid}>
            Save
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default WriterForm;
