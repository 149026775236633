import NavigateBackButton from "../../utils/NavigateBackButton";
import ContentWrapper from "../ContentWrapper";

const NotFound = () => (
  <ContentWrapper>
    <NavigateBackButton />
    <h1 className="text-4xl text-overline">
      404 - Still haven't found what im looking for
    </h1>
  </ContentWrapper>
);

export default NotFound;
