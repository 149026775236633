import { gql } from "@apollo/client";

export const GET_SETTLEMENT_STATUSES = gql`
  query {
    settlementsUploaded {
      date
      company
      isProcessed
      isProcessing
      totalRows
      insertedRows
    }
  }
`;

export const GET_RELEASE_SCHEDULE = gql`
  query getReleaseDateInformation($startDate: String!, $endDate: String!) {
    releaseDateInformation(startDate: $startDate, endDate: $endDate) {
      date
      categories {
        categoryName
        numberOfReleases
        genres {
          genreName
          numberOfReleases
          releases
        }
      }
    }
  }
`;

export const GET_COMPILATIONS = gql`
  query GetCompilations {
    getCompilations {
      upc
      fugaProductId
      metadata {
        filterAlgorithm
        genre {
          id
          genreName
        }
        provider {
          name
        }
      }
    }
  }
`;

export const typeDefs = gql`
  scalar Long

  type Track {
    isrc: String!
    audioLocale: String!
    isAvailableSeparately: Boolean
    audioLanguage: String
    mainGenre: String
    mainSubGenre: String
    title: String
    isParentalAdvisory: Boolean
    primaryArtist: String
    featuredArtists: [String!]
    trackVersion: String
    publishers: [Publisher!]
    contributors: [Contributor!]
    pLineText: String
    pLineYear: String
    qsGenre: String
    qsCategory: String
    royaltyClaims: [RoyaltyClaim!]
  }

  type CoverArtFileInfo {
    UPC: Long!
    originalFileName: String!
    fileName: String!
    fileSize: Int!
    crc32Checksum: String!
  }

  input TrackInfoInput {
    isrc: String
    sequenceNumber: Int
  }

  enum ReleaseFormatEnum {
    SINGLE
    ALBUM
    EP
    BOXSET
  }

  enum DeliveryAction {
    DELIVER
    TAKEDOWN
    UPDATE
  }

  enum DeliveryType {
    STEREO
    ATMOS
    MEAD
  }
`;
