import { ApolloClient, ApolloLink, InMemoryCache } from "@apollo/client";

import { typeDefs } from "../utils/GraphQLQueries";
import { omitDeep } from "../utils/Helpers";

let apolloClient: ApolloClient<InMemoryCache>;

/**
 * Remove __typename field from mutations
 */
export const cleanTypeName = new ApolloLink((operation, forward) => {
  //@ts-ignore
  if (operation.query.definitions[0].operation === "mutation") {
    if (operation.variables) {
      operation.variables = omitDeep(operation.variables, "__typename");
    }
  }
  return forward(operation);
});

function createApolloclient() {
  return new ApolloClient({
    link: ApolloLink.from([cleanTypeName]),
    cache: new InMemoryCache({
      possibleTypes: {
        IBasicTrack: ["Track", "CollectionTrack"],
      },
    }),
    typeDefs,
  });
}

export function initializeApollo() {
  const _apolloClient = apolloClient ?? createApolloclient();
  if (!apolloClient) apolloClient = _apolloClient;
  return _apolloClient;
}
