import { Role, useAuth } from "../auth/AuthProvider";

const AdminVisibleWrapper = ({ children }: { children: JSX.Element }) => {
  const { isAuthorizedTo } = useAuth();

  if (isAuthorizedTo(Role.ADMIN, Role.SUPER_ADMIN)) {
    return children;
  } else {
    return null;
  }
};

export default AdminVisibleWrapper;
