import { Cell, Column, Table } from "@tanstack/react-table";

function escapeCSVValue(value: any): string {
  if (value === null || value === undefined || typeof value !== "string") {
    return "";
  }
  return `"${value.replace(/"/g, '""')}"`;
}

function generateCSVHeaders<T extends object>(
  columns: Column<T, unknown>[]
): string {
  return columns.map((column) => escapeCSVValue(column.id)).join(",");
}

function generateCSVRow<T extends object>(cells: Cell<T, unknown>[]): string {
  return cells
    .map((cell) => escapeCSVValue((cell.getValue() as string) ?? ""))
    .join(",");
}

function convertToCSV<T extends object>(table: Table<T>): string {
  const headers = table
    .getAllColumns()
    .map((column) => generateCSVHeaders([column]));

  const rowsAsCSV = table
    .getFilteredRowModel()
    .rows.map((row) => generateCSVRow(row.getAllCells()))
    .join("\n");

  return [headers, rowsAsCSV].join("\n");
}

function downloadCSV(csv: string, filename: string): void {
  const blob = new Blob([csv], { type: "text/csv" });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  Object.assign(a, {
    href: url,
    download: filename,
    style: "display: none",
  });
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
}

function exportTableCSV<T extends object>(
  table: Table<T>,
  fileName: string = "table_data"
): void {
  const csv = convertToCSV(table);
  downloadCSV(csv, `${fileName}.csv`);
}

export default exportTableCSV;
