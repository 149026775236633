export interface X5Genre {
  id: number;
  genreName: string;
}

export enum X5DspConfig {
  DSPS_ALL_MINUS_AMAZON_YOUTUBE = "DSPS_ALL_MINUS_AMAZON_YOUTUBE",
  DSPS_ALL_MINUS_AMAZON_YOUTUBE_APPLE_SPOTIFY = "DSPS_ALL_MINUS_AMAZON_YOUTUBE_APPLE_SPOTIFY",
  DSPS_ALL_MINUS_AMAZON_YOUTUBE_SPOTIFY = "DSPS_ALL_MINUS_AMAZON_YOUTUBE_SPOTIFY",
  DSPS_ALL_MINUS_AMAZON_YOUTUBE_APPLE = "DSPS_ALL_MINUS_AMAZON_YOUTUBE_APPLE",
}
