import AsyncSelect from "react-select/async";

import _, { debounce } from "lodash";
import { REACT_SELECT_CUSTOM_STYLE } from "../../utils/ReactSelectConfig";

interface FetchableDropDownProps<T> {
  name?: string;
  label?: string;
  header?: string;
  placeHolder?: string;
  initialValue?: T;
  fetchFunction: (search: string) => Promise<T[]>;
  className?: string;
  getOptionLabel?: (value: T) => string;
  getOptionValue?: (value: T) => string;
  onSelect: (value: T) => void;
  onClear?: () => void;
}

const SearchableDropDownAsync = <T,>(props: FetchableDropDownProps<T>) => {
  const {
    name,
    placeHolder,
    onSelect,
    initialValue,
    getOptionLabel,
    getOptionValue,
    fetchFunction,
  } = props;
  return (
    <AsyncSelect<T & any>
      name={name}
      className="border bg-white px-2 rounded-lg text-interactive h-min w-full"
      cacheOptions
      defaultOptions
      placeholder={placeHolder}
      menuPortalTarget={document.body}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      value={initialValue}
      onChange={onSelect}
      styles={REACT_SELECT_CUSTOM_STYLE}
      loadOptions={debounce(
        (inputValue) =>
          fetchFunction(inputValue).then((data) => _.take(data, 1000)),
        500,
        {
          leading: true,
          maxWait: 1000,
        }
      )}
    />
  );
};

export default SearchableDropDownAsync;
