import React, { ReactNode, useCallback } from "react";
import ContentWrapper from "../ContentWrapper";
import { useFirebase } from "./firebase/useFirebase";
import { User } from "firebase/auth";

interface AuthProviderProps {
  children: ReactNode | ReactNode[];
}

export enum Role {
  ADMIN = "admin",
  FINANCIAL_READ = "financial.read",
  BULK_WRITE = "bulk.write",
  SUPER_ADMIN = "superadmin",
}

const AuthContext = React.createContext<{
  getBearerToken: () => Promise<string>;
  getCompleteAuthResponse: () => Promise<gapi.auth2.AuthResponse>;
  isAuthenticating: boolean;
  isAuthenticated: boolean;
  isAuthorizedTo: (...role: Role[]) => boolean;
  signIn: () => void;
  signOut: () => void;
  currentUser: User | null | undefined;
}>({
  getBearerToken: () => Promise.resolve(""),
  getCompleteAuthResponse: () => Promise.reject(),
  isAuthenticating: true,
  isAuthenticated: false,
  isAuthorizedTo: (...role: Role[]) => false,
  signIn: () => undefined,
  signOut: () => undefined,
  currentUser: undefined,
});

const AuthProvider = (props: AuthProviderProps) => {
  const {
    signIn,
    signOut,
    currentUser,
    userRoles,
    getBearerToken: getBearerTokenFirebase,
    getCompleteAuthResponse,
    isAuthenticating,
  } = useFirebase();

  const isAuthorizedTo = useCallback(
    (...roles: Role[]) => {
      if (userRoles?.includes(Role.ADMIN)) return true;

      return (
        roles
          .map((role) => userRoles?.includes(role))
          .filter((authorizedRole) => authorizedRole === true).length > 0
      );
    },
    [userRoles]
  );

  return (
    <AuthContext.Provider
      value={{
        getBearerToken: getBearerTokenFirebase,
        getCompleteAuthResponse,
        isAuthenticating,
        isAuthenticated: !!currentUser,
        isAuthorizedTo,
        signIn,
        signOut,
        currentUser,
      }}
    >
      {isAuthenticating ? (
        <ContentWrapper>
          <h2 className="w-screen flex items-center justify-center text-large-title">
            Beaming you up...
          </h2>
        </ContentWrapper>
      ) : (
        props.children
      )}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => React.useContext(AuthContext);
