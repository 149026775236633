import { ApolloError, DocumentNode } from "@apollo/client";
import Select, { ActionMeta } from "react-select";
import {
  DropdownIndicator,
  REACT_SELECT_CUSTOM_STYLE,
  SearchIndicator,
} from "../../utils/ReactSelectConfig";
import { deprecate } from "util";

interface SearchableDropDownProps<T> {
  options?: T[];
  loading?: boolean;
  //If parent is a form
  name?: string;
  label?: string;
  header?: string;
  isMulti?: boolean;
  placeHolder?: string;
  isFormField?: boolean;
  formFieldError?: string;
  /**
   * This paremeter is confusing, as it does not reflect an initial value, but rather a value for a controlled component.
   * TODO investigate if this can be renamed to `value` or `selectedValue`
   */
  initialValue?: T;
  apolloError?: ApolloError;
  query?: DocumentNode;
  className?: string;
  isSearch?: boolean;
  isClearable?: boolean;

  getOptionLabel?: (value: T) => string;
  getOptionValue?: (value: T) => string;
  onSelect: (value: T) => void;
  onClear?: () => void;
}

/**
@deprecated Use `GenericDropDown`  or other variants of it instead
 */
const SearchableDropDown = <T,>(props: SearchableDropDownProps<T>) => {
  const {
    name,
    label,
    isFormField,
    formFieldError,
    placeHolder,
    loading,
    onSelect,
    initialValue,
    getOptionLabel,
    getOptionValue,
    apolloError: dataError,
    className,
    isSearch,
    isClearable,
    onClear,
    options,
  } = props;

  const onSelectCallback = (value: T | null, actionMeta: ActionMeta<any>) => {
    if (!!onSelect && value) {
      onSelect(value);
    }
    if (actionMeta.action === "clear" && !!onClear) {
      onClear();
    }
  };

  if (isFormField) {
    return (
      <div className={`${className ? className : "w-full"} `}>
        {label ? (
          <div className="text-regular-body-bold text-text-secondary text-neutral-semiheavy mb-2">
            {label}
          </div>
        ) : null}
        <Select<T & any>
          name={name}
          options={options || []}
          className="border bg-white px-2 focus-within text-interactive rounded-xl w-full shadow-sm "
          menuPortalTarget={document.body}
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
          value={initialValue}
          isLoading={loading}
          styles={REACT_SELECT_CUSTOM_STYLE}
          placeholder={placeHolder || label}
          onChange={onSelectCallback}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: "#F3F4F6",
              primary50: "#E4F1EF",
              primary: "#04856C",
            },
          })}
        />
        {formFieldError ? (
          <div className="text-error">{formFieldError}</div>
        ) : null}
        {dataError ? (
          <div className="text-error">{dataError.message}</div>
        ) : null}
      </div>
    );
  }

  return (
    <Select<T & any>
      name={name}
      options={options}
      className={`border px-2 rounded-lg text-interactive w-full ${
        className ? className : ""
      }`}
      menuPortalTarget={document.body}
      getOptionLabel={
        getOptionLabel || ((value: T & { label: string }) => value.label)
      }
      getOptionValue={
        getOptionValue || ((value: T & { value: string }) => value.value)
      }
      components={{
        DropdownIndicator: isSearch?.valueOf()
          ? DropdownIndicator
          : SearchIndicator,
      }}
      value={initialValue}
      isLoading={loading}
      onChange={onSelectCallback}
      styles={REACT_SELECT_CUSTOM_STYLE}
      placeholder={placeHolder || label}
      isClearable={isClearable}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: "#F3F4F6",
          primary50: "#E4F1EF",
          primary: "#04856C",
        },
      })}
    />
  );
};

export default SearchableDropDown;
