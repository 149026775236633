import React, { useEffect, useState } from "react";
import { useFileDownloader } from "../../utils/useFileDownloader";
import { useDispatch, useSelector } from "react-redux";

import CoverArtDisplay from "../products/CoverArtDisplay";
import { selectAudioPlayerState } from "../../redux/audioplayer/selectors";
import { setIsPlaying, setTrack } from "../../redux/audioplayer/actions";
import { Link } from "react-router-dom";
import useTrack from "../../hooks/useTrack";

const AudioPlayer = React.memo(() => {
  const audioPlayerState = useSelector(selectAudioPlayerState());
  const { currentIsrc, currentUPC, isPlaying } = audioPlayerState;
  const dispatch = useDispatch();
  const { getAuthorizedAudioUrl } = useFileDownloader();
  const [audioUrl, setAudioUrl] = useState<string | undefined>();
  const { track, getTrack } = useTrack();

  const fetchAudioUrl = async () => {
    if (currentIsrc) {
      try {
        const url = await getAuthorizedAudioUrl(currentIsrc);
        setAudioUrl(url);
        getTrack(currentIsrc);
      } catch (error) {
        // Handle the error appropriately
        console.error("Failed to fetch audio URL:", error);
      }
    }
  };

  useEffect(() => {
    fetchAudioUrl();
  }, [currentIsrc]);

  if (!track || !isPlaying) {
    return null;
  }

  return (
    <div className="bottom-0 sticky z-50 bg-neutral-background">
      <div className=" flex justify-between">
        <div className="float-left flex">
          <CoverArtDisplay
            upc={currentUPC}
            size="160px"
            roundedLeftCorners={false}
            noControls
          />
          <div className="rounded-r-md px-2 bg-gray-200">
            <div className="grid grid-cols-3">
              <div className="col-span-2">
                <div className="text inline-flex">{track.title}</div>
              </div>
              <div className="col-3">
                <div className="text-xs my-1 inline-flex float-right mr-3">
                  ({track.isCover ? "Cover" : "Original"})
                </div>
              </div>
            </div>

            <div className="grid grid-cols-2 mt-3">
              <div className="col-1">
                {track.primaryArtists.map((a) => (
                  <div className="text-xs hover:font-medium">
                    <Link to={`/products?search=${a.artistName}`}>
                      {a.artistName}
                    </Link>
                  </div>
                ))}

                <div className="text-xs">{track.qsGenreString}</div>
              </div>
              <div className="col-2">
                <div className="text-xs float-right mr-3">{track.isrc}</div>
                <div className="text-xs hover:font-medium float-right mr-3">
                  <Link to={`/products/${currentUPC}`}>{currentUPC}</Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full pt-5">
          <audio
            controls
            autoPlay={isPlaying}
            src={audioUrl}
            className="mb-4 mx-4"
            style={{ width: "95%" }}
          >
            <a href={audioUrl}>Download audio</a>
          </audio>
        </div>

        <div className="pt-6 pr-4 rounded-md ">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="2.2em"
            viewBox="0 0 512 512"
            className="float-right bg-gray-100 text-red hover:cursor-pointer rounded-full transform hover:scale-110"
            onClick={() => {
              dispatch(setTrack(undefined));
              dispatch(setIsPlaying(false));
            }}
          >
            <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z" />
          </svg>
        </div>
      </div>
    </div>
  );
});

export default AudioPlayer;
