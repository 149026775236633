import { ReactNode, useEffect, useState } from "react";
import { ErrorIcon, InfoIcon, SuccessIcon, WarningIcon } from "../../utils/Icons";

export interface TileProps {
  content?: ReactNode;
  messageTitle?: string;
  auto?: boolean;
  onClose?: () => void;
  popIn?: boolean;
  type: "error" | "warning" | "info" | "success";
}

const typeToIconMap = {
  success: <SuccessIcon />,
  info: <InfoIcon />,
  warning: <WarningIcon />,
  error: <ErrorIcon />,
};

const backgroundColors = {
  info: "bg-info-light",
  warning: "bg-warning-light",
  error: "bg-error-light",
  success: "bg-success-light",
};

const borderColors = {
  info: "border-info-trim",
  warning: "border-warning-trim",
  error: "border-error-trim",
  success: "border-success-trim",
};

export const Tile = (props: TileProps) => {
  const { messageTitle, type, content, popIn = false, onClose } = props;

  const [show, setshow] = useState(false);

  useEffect(() => {
    if (content) {
      setshow(true);
    }
  }, [content]);

  return (
    <div
      className={`message text-${type} border-2 ${borderColors[type]} overflow-hidden rounded-xl ${
        backgroundColors[type]
      } p-4 ${show ? "visible" : "hidden"}`}
    >
      <div className="header flex justify-between w-full">
        <div className="flex">
          <div className="mr-3">{typeToIconMap[type] || null}</div>
          <div className="grid">
            <span className="text-regular-body-bold">{messageTitle || ""}</span>
            <span className="break-words text-caption">{content}</span>
          </div>
        </div>
        <div className="flex">
          {popIn && (
            <div
              className={type}
              onClick={() => {
                setshow(false);
                if (onClose) {
                  onClose();
                }
              }}
            >
              <i className={`fas fa-times text ${type} no-margin`} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
