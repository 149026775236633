import { ReactNode } from "react";
import { Action } from "redux";
import { isType } from "typescript-fsa";
import * as actions from "./actions";

export interface GlobalMessagesState {
  [messageName: string]: GlobalMessageComponent;
}

export interface GlobalMessageComponent {
  id: string;
  component: ReactNode;
  type?: "success" | "info" | "warning" | "error";
  displayTime?: number;
  title?: string;
  closeNow?: boolean;
  minimized?: boolean;
}

const initState = {};

function reducer(
  state: GlobalMessagesState = initState,
  action: Action
): GlobalMessagesState {
  if (isType(action, actions.addMessage)) {
    state[action.payload.id] = action.payload;

    return { ...state };
  }

  if (isType(action, actions.closeMessage)) {
    if (state[action.payload]) {
      state[action.payload].closeNow = true;
    }
    return { ...state };
  }

  if (isType(action, actions.deleteMessage)) {
    if (state[action.payload]) {
      delete state[action.payload];
    }
    return { ...state };
  }

  return state;
}

export default reducer;
