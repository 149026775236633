import React from "react";

import { Navigate, Outlet } from "react-router-dom";
import { Role, useAuth } from "../components/auth/AuthProvider";

/**
 * roles roles needed to authorize for the route.
 */
const AuthorizedRoute = (props: { roles?: Role[] }) => {
  const { roles } = props;
  const { isAuthenticated, isAuthorizedTo } = useAuth();

  if (isAuthenticated) {
    if (roles) {
      return isAuthorizedTo(...roles) ? <Outlet /> : <Navigate to={"/"} />;
    }
    return <Outlet />;
  }

  return <Navigate to={"/login"} />;
};

export default AuthorizedRoute;
