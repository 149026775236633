const ProgressBar = (props: {
  progress: number;
  noBar?: boolean;
  noText?: boolean;
}) => {
  const complete = props.progress === 100;
  return (
    <div className="flex items-center">
      {!props.noBar?.valueOf() && (
        <span
          className={`border border-neutral-trim rounded-full p-1 pr-4 text-right bg-interactive ${
            complete ? "bg-interactive" : ""
          } text white my-1`}
          style={{ width: `${props.progress}%` }}
        />
      )}

      {!props.noText && (
        <h3
          className={`text-neutral ${complete && "text-interactive"} ml-1 `}
          style={{
            position: "relative",
          }}
        >
          {props.progress || 0}%
        </h3>
      )}
    </div>
  );
};

export default ProgressBar;
