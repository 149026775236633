import { useParams } from "react-router-dom";
import ContentWrapper from "../../ContentWrapper";
import NavigateBackButton from "../../../utils/NavigateBackButton";
import AwTabs from "../../multiviewmenu/AwTabs";
import AwTab from "../../multiviewmenu/AwTab";
import usePublishers from "../../../hooks/usePublishers";
import NotFound from "../../notfound/NotFound";
import { parseOptionalInt } from "../../../utils/Helpers";

const PublisherPage = () => {
  const { publisherId } = useParams();
  const { publisher, isLoading } = usePublishers(parseOptionalInt(publisherId));

  if (!isLoading && !publisher) {
    return <NotFound />;
  }

  return (
    <ContentWrapper title={publisher?.publisherName}>
      <NavigateBackButton toPath="/administration/publishers" />
      <AwTabs>
        <AwTab to={`/administration/publishers/${publisherId}`}>Details</AwTab>
        <AwTab to={`/administration/publishers/${publisherId}/writers`}>
          Writers
        </AwTab>
      </AwTabs>
    </ContentWrapper>
  );
};
export default PublisherPage;
