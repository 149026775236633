import { useParams } from "react-router-dom";
import ContentWrapper from "../../ContentWrapper";
import NavigateBackButton from "../../../utils/NavigateBackButton";
import AwTabs from "../../multiviewmenu/AwTabs";
import AwTab from "../../multiviewmenu/AwTab";
import useWriters from "../../../hooks/useWriters";
import NotFound from "../../notfound/NotFound";
import { parseOptionalInt } from "../../../utils/Helpers";

const WriterPage = () => {
  const { writerId } = useParams();
  const { writer } = useWriters(parseOptionalInt(writerId));

  if (!writer) {
    return <NotFound />;
  }
  return (
    <ContentWrapper title={writer?.writerName}>
      <NavigateBackButton toPath="/administration/writers" />
      <AwTabs>
        <AwTab to={`/administration/writers/${writerId}`}>Details</AwTab>
        <AwTab to={`/administration/writers/${writerId}/tracks`}>Tracks</AwTab>
      </AwTabs>
    </ContentWrapper>
  );
};
export default WriterPage;
